import {DataColumn} from "@common/domain/common.model";
import {ChecksheetEntryEventRef} from "@views/checksheets/domain/checksheets.model";

export const Sample = {};

export const ChecksheetEventFilters = [
    {name: "All Time", id: "allTime"},
    {name: "Today", id: "today"},
    {name: "Yesterday", id: "yesterday"},
    {name: "This Month", id: "thisMonth"},
]

export const ChecksheetsMinimalColumns: DataColumn[] = [
    {
        key: "title",
        label: "Title",
    },
    {
        key: "description",
        label: "Description",
    },
];

export const ChecksheetsEventDetailsMockData: ChecksheetEntryEventRef[] = [
    {
        updatedBy: "John Doe",
        id: "cewiudhe",
        value_update_size: 19,
        status: "COMPLETED",
        entry_id: "edce",
        remarks: "This is a test remark",
        event_type: "VALUE_UPDATE", //| "STATUS_UPDATE"
        column_cordinates: "A",
        updatedAt: "2024-06-02T12:00:00Z", //Not sure if this should be here.
    },
    {
        updatedBy: "John Not Doe",

        id: "qiduhwqwd",
        value_update_size: 12,
        status: "APPROVED",
        entry_id: "cwewew",
        remarks: "This is a test remark",
        event_type: "STATUS_UPDATE", //| "STATUS_UPDATE"
        column_cordinates: "B",
        updatedAt: "2021-09-01T12:00:00Z", //Not sure if this should be here.
    },
    {
        updatedBy: "John Doe",

        id: "cewdhuewwq",
        value_update_size: 122,
        status: "COMPLETED",
        entry_id: "dwcewce",
        remarks: "This is a test remark",
        event_type: "VALUE_UPDATE", //| "STATUS_UPDATE"
        column_cordinates: "B",
        updatedAt: "2021-09-02T12:00:00Z", //Not sure if this should be here.
    },
    {
        updatedBy: "John Not Doe",

        id: "efcewcqwqw",
        value_update_size: 122,
        status: "COMPLETED",
        entry_id: "dwcewce",
        remarks: "This is a test remark",
        event_type: "VALUE_UPDATE", //| "STATUS_UPDATE"
        column_cordinates: "C",
        updatedAt: "2021-09-03T12:00:00Z", //Not sure if this should be here.
    },
    {
        updatedBy: "John Not Doe",

        id: "ytgb",
        value_update_size: 122,
        status: "COMPLETED",
        entry_id: "dwcewce",
        remarks: "This is a test remark",
        event_type: "VALUE_UPDATE", //| "STATUS_UPDATE"
        column_cordinates: "D",
        updatedAt: "2021-09-03T12:00:00Z", //Not sure if this should be here.
    },
    {
        updatedBy: "John Doe",

        id: "cdwweedwed",
        value_update_size: 122,
        status: "COMPLETED",
        entry_id: "dwcewce",
        remarks: "This is a test remark",
        event_type: "VALUE_UPDATE", //| "STATUS_UPDATE"
        column_cordinates: "E",
        updatedAt: "2021-09-04T12:00:00Z", //Not sure if this should be here.
    },
];
