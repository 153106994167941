import React, {createContext, PropsWithChildren, useEffect} from "react"
import {useSelector} from "react-redux"
import {RootState} from "@store"
import {useLocation, useNavigate} from "react-router-dom"
import {logout} from "@slices/auth.slice"
import {useAppDispatch} from "@src/redux/hooks"

interface ISecurityContext {
    isAuthenticated: () => boolean,
    user: () => any | undefined
}

const AuthenticationContext = createContext<ISecurityContext>({isAuthenticated: () => false, user: () => undefined})

export const useSecurity = () => {
    const context = React.useContext(AuthenticationContext)
    if (!context) {
        throw new Error('useAuthentication must be used within a AuthenticationProvider')
    }
    return context

}

export default function SecurityContext({children}: PropsWithChildren<{ id?: string }>) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const authentication = useSelector((state: RootState) => state.auth)

    const isAuthenticated = () => {
        return authentication.status === 'LOGGED_IN'
    }

    const user = () => {
        return authentication.user.data
    }

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login')
        } else {
            if (authentication.user.data && authentication.user.data.expiresAt && authentication.user.data.issuedAt) {
                if (authentication.user.data.expiresAt <= authentication.user.data.issuedAt) {
                    dispatch(logout())
                } else {
                    const logoutTime = (authentication.user.data.expiresAt - authentication.user.data.issuedAt) * 1000
                    setTimeout(() => dispatch(logout()), logoutTime)
                }
            }

            if (location.pathname === '/login') navigate('/')
        }
    }, [authentication])

    return (
        <AuthenticationContext.Provider value={{isAuthenticated, user}}>
            {children}
        </AuthenticationContext.Provider>
    )
}