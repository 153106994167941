import React, {createContext, PropsWithChildren, useState} from "react"
import {useAppDispatch} from "@src/redux/hooks"
import {useSelector} from "react-redux"
import {RootState} from "@store"
import {DEFAULT_MODEL_TAG} from "@src/constants/common.const"
import {useStateTrackedArraySelector} from "@utils/selector"

interface IConfigContext {
    getConfig: () => string
    refreshConfig: () => void

}

const ConfigurationContext = createContext<IConfigContext | undefined>(undefined)

export const useConfiguration = () => {
    const context = React.useContext(ConfigurationContext)
    if (!context) {
        throw new Error('useConfiguration must be used within a ConfigurationProvider')
    }
    return context

}

export default function ConfigurationProvider({children}: PropsWithChildren<NonNullable<unknown>>) {
    const [dataState, setDataState] = useState<'empty' | 'loaded' | 'loading'>('empty')
    const models = useStateTrackedArraySelector((state: RootState) => state.models.minimal[DEFAULT_MODEL_TAG])
    const levels = useStateTrackedArraySelector((state: RootState) => state.skills.levels)
    const skills = useStateTrackedArraySelector((state: RootState) => state.skills.minimal)
    const departments = useStateTrackedArraySelector((state: RootState) => state.departments.minimal)
    const authentication = useSelector((state: RootState) => state.auth)
    const dispatch = useAppDispatch()


    // const loadInitialData = async () => {
    //     if (dataState === 'empty' && authentication.status === 'LOGGED_IN') {
    //         setDataState('loading')
    //         try {
    //             await dispatch(fetchConfiguration())
    //             await dispatch(fetchLinesList({ tag: DEFAULT_LINE_TAG, parameters: {} }))
    //             setDataState('loaded')
    //         } catch (e) {
    //             console.log("Error loading initial data", e)
    //             setDataState('empty')
    //         }
    //     }
    // }
    //
    // useEffect(() => {
    //     loadInitialData()
    // }, [authentication.status])
    //
    // useEffect(() => {
    //     if (dataState === 'loaded') {
    //         if (levels.status === 'empty') {
    //             dispatch(fetchLevelList())
    //         }
    //         if (skills.status === 'empty') {
    //             dispatch(fetchSkillList())
    //         }
    //         if (departments.status === 'empty') {
    //             dispatch(fetchDepartmentsList({ tag: DEFAULT_DEPARTMENT_TAG }))
    //         }
    //     }
    // }, [dataState, levels.status, skills.status, departments.status])
    //
    const getConfig = () => {
        return ""
    }

    const refreshConfig = () => {
        console.log("Refreshing configuration")
    }
    //
    // if (dataState === 'loading') {
    //     return <div>Loading...</div>
    // }

    return (
        <ConfigurationContext.Provider value={{getConfig, refreshConfig}}>
            {children}
        </ConfigurationContext.Provider>
    )

}