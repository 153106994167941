import {createAsyncThunk, SerializedError} from "@reduxjs/toolkit"
import {ApplicationResponse, TaggedDataState} from "@src/common/domain/common.model"
import {TaggedAction} from "@src/common/domain/common.props"
import AppConstants from "@src/environment/app.constants"
import api from "@common/services/api.service"
import {NewShiftRef, ShiftMinimalRef, ShiftStore} from "@views/pms/domain/shift.model"
import moment from "moment-timezone";
import {createSecuredSlice} from "@utils/auth.utils";


export const fetchShiftList =
    createAsyncThunk<TaggedDataState<ShiftMinimalRef[]>, TaggedAction>("shift/list",
        async (payload) => {
            const url = `${AppConstants.api}/shift`
            const response = await api.get<ApplicationResponse<ShiftMinimalRef[]>>(url)

            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

export const uploadShift = createAsyncThunk<void, {
    payload: NewShiftRef
}>("shift/upload",
    async ({payload}, thunkAPI) => {
        try {

            const url = `${AppConstants.api}/shift`


            const start = moment(payload.start_time, 'HH:mm')
            const end = moment(payload.end_time, 'HH:mm')

            const spansToNextDay = end.isBefore(start)

            const apiPayload = {
                id: payload.id,
                name: payload.name,
                start_time: start.format('HH:mm'),
                end_time: end.format('HH:mm'),
                spans_to_next_day: spansToNextDay,
                activities: payload.activities.map((activity: any) => ({
                    id: activity.id,
                    name: activity.name,
                    start_time: activity.start_time,
                    duration: activity.duration * 60,
                })),
            };
            if (payload.id) {

                const response = await api.put<ApplicationResponse<void>>(url, apiPayload)
                return response.data.data
            } else {
                const response = await api.post<ApplicationResponse<void>>(url, apiPayload)
                return response.data.data
            }
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

export const deleteShift = createAsyncThunk<void, {
    // configId: string,
    shiftId: string
},
    { rejectValue: SerializedError }>("shift/deleteShift",
    async (payload, thunkAPI) => {
        try {
            const {shiftId} = payload

            const response = await api.delete(`${AppConstants.api}/shift?id=${shiftId}`)

            return response.data

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
)

export const fetchShiftById = createAsyncThunk<TaggedDataState<ShiftMinimalRef | undefined>, {
    shiftId: string,
    tag: string
}, { rejectValue: SerializedError }
>("shift/fetchById",
    async (payload, thunkAPI) => {
        try {
            const {shiftId, tag} = payload
            const url = `${AppConstants.api}/shift/${shiftId}`;
            const response = await api.get<ApplicationResponse<ShiftMinimalRef>>(url);

            return {
                tag,
                data: response.data.data || undefined
            };
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
);


const shiftSlice = createSecuredSlice({
    name: "shift",
    initialState: {minimal: {}, detail: {}} as ShiftStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShiftList.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchShiftList.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.minimal[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }
                })
            .addCase(fetchShiftList.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.minimal[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
            .addCase(fetchShiftById.pending, (state, action) => {
                const {tag} = action.meta.arg;
                state.detail[tag] = {
                    status: "loading",
                    error: null,
                    data: undefined
                };
            })
            .addCase(fetchShiftById.fulfilled,
                (state, action) => {
                    const {tag, data} = action.payload;
                    state.detail[tag] = {
                        status: "idle",
                        error: null,
                        data
                    };
                })
            .addCase(fetchShiftById.rejected, (state, action) => {
                const {tag} = action.meta.arg;
                state.detail[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: undefined
                };
            });
    }
})

export default shiftSlice.reducer