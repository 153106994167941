import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import AppConstants from "@src/environment/app.constants"
import api from "@src/common/services/api.service"
import moment from "moment-timezone"
import {EntryMinimalRef, EntryStore, NewEntryRef} from "@views/pms/domain/entries.model"
import {createSecuredSlice} from "@utils/auth.utils"

export const fetchCurrentEntryForLine = createAsyncThunk<TaggedDataState<EntryMinimalRef | undefined>, string>(
    "entries/fetchCurrentEntryForLine",
    async (payload, thunkAPI) => {
        try {
            if (payload == null) throw new Error("Line ID is required")

            const url = `${AppConstants.api}/entry/current?lineId=${payload}`
            const response = await api.get<ApplicationResponse<EntryMinimalRef>>(url)
            return {
                tag: payload,
                data: response.data.data
            }
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

export const fetchEntriesBetweenDates =
    createAsyncThunk<TaggedDataState<EntryMinimalRef[]>, TaggedAction>("entries/fetchEntriesBetweenDates",
        async (payload) => {
            const {
                assemblyId,
                entryStartDate,
                entryEndDate
            } = payload.parameters
            const url = `${AppConstants.api}/entry?lineId=${assemblyId}&startDate=${entryStartDate}&endDate=${entryEndDate}`

            const response = await api.get<ApplicationResponse<EntryMinimalRef[]>>(url)

            const data = (response.data.data ?? []).map((record) => {
                return {
                    ...record,
                    activity: record.activity.map((act) => ({
                        ...act,
                        runsOnNextDay: act.date !== record.date
                    }))
                }
            })

            return {
                tag: payload.tag,
                data: data
            }

        }
    )

export const deleteEntry = createAsyncThunk<void, string>("entry/delete",
    async (payload, thunkAPI) => {

        if (payload == null) throw new Error("Entry ID is required")
        try {

            const url = `${AppConstants.api}/entry`
            const response = await api.delete<ApplicationResponse<void>>(`${url}?id=${payload}`)

            return response.data.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

export const uploadEntry = createAsyncThunk<void, {
    payload: NewEntryRef
}>("entry/upload",
    async ({payload}, thunkAPI) => {

        try {

            // console.log(payload);

            const url = `${AppConstants.api}/entry`

            let finalPayload
            if (payload.id) {
                finalPayload = {
                    id: payload.id,
                    start_time: payload.startTime,
                    end_time: payload.endTime,
                    production_target: payload.productionTarget,
                    title: payload.title,
                    model: payload.modelId,
                    oee_target: payload.oeeTarget,
                    rejection_threshold: payload.rejectionThreshold,
                    activity_minimal: payload.activities.map((activity: any) => ({
                        id: activity.id,
                        name: activity.name,
                        is_custom: activity.isCustom,
                        start_time: activity.startTime,
                        duration: activity.duration * 60,
                        date: activity.date,
                    })),
                }

            } else {
                finalPayload = {
                    ...payload,
                    modelId: payload.modelId,
                    startDate: moment(payload.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    endDate: moment(payload.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    activities: payload.activities.map((activity: any) => ({
                        ...activity,
                        duration: activity.duration * 60,

                    })),
                }
            }

            if (payload.id) {
                const response = await api.put<ApplicationResponse<void>>(url, finalPayload)
                return response.data.data
            } else {
                const response = await api.post<ApplicationResponse<void>>(url, finalPayload)
                return response.data.data
            }


        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

const plannerSlice = createSecuredSlice({
    name: "pms",
    initialState: {minimal: {}, currentEntry: {}} as EntryStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentEntryForLine.pending,
                (state, action: { meta: { arg: string } }) => {
                    const { arg } = action.meta
                    state.currentEntry[arg] = {
                        status: "loading",
                        error: null,
                        data: undefined
                    }
                })
            .addCase(fetchCurrentEntryForLine.fulfilled, (state, action) => {
                const { tag, data } = action.payload

                state.currentEntry[tag] = {
                    status: "idle",
                    error: null,
                    data: data
                }
            })
            .addCase(fetchCurrentEntryForLine.rejected, (state, action) => {
                const { arg } = action.meta
                state.currentEntry[arg] = {
                    status: "failed",
                    error: action.error.message,
                    data: undefined
                }
            })
            .addCase(fetchEntriesBetweenDates.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchEntriesBetweenDates.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchEntriesBetweenDates.rejected, (state,
                                                         action: PayloadAction<unknown, string,
                                                             { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state.minimal[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default plannerSlice.reducer