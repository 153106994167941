import {createAsyncThunk} from "@reduxjs/toolkit"
import api from "@common/services/api.service"
import {ApplicationResponse, StateTrackedData} from "@common/domain/common.model"
import {DashboardRef} from "@views/dashboard/domain/dashboard.model"
import AppConstants from "@src/environment/app.constants"
import {createSecuredSlice} from "@utils/auth.utils";


export const fetchDashboard = createAsyncThunk("dashboard/fetchDashboard", async () => {
    const response = await api.get<ApplicationResponse<DashboardRef>>(`${AppConstants.api}/dashboard/info`)

    return response.data.data
})


const dashboardSlice = createSecuredSlice({
    name: "dashboard",
    initialState: {status: "empty"} as StateTrackedData<DashboardRef | undefined>,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboard.pending, (state) => {
                state.status = "loading"
                state.error = undefined
                state.data = undefined
            })
            .addCase(fetchDashboard.fulfilled, (state, action) => {
                state.status = "idle"
                state.error = undefined
                state.data = action.payload
            })
            .addCase(fetchDashboard.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
                state.data = undefined
            })
    }
})

export default dashboardSlice.reducer