import {DialogMetadataRef, DialogRef} from "@common/domain/common.model"
import {createAsyncThunk, PayloadAction} from "@reduxjs/toolkit"
import {ReactNode} from "react"
import {createSecuredSlice} from "@utils/auth.utils";

const dialogStore = new Map<string, ReactNode>()


export const openDialog = createAsyncThunk(
    "dialogs/openDialog",
    (dialog: Omit<DialogRef, "content"> & { content: ReactNode }) => {
        const id = dialog.metadata?.id ?? Math.random().toString(36).substr(2, 9)
        dialogStore.set(id, dialog.content)
        return {
            metadata: {
                id,
                variant: dialog.metadata?.variant ?? "dialog",
                size: dialog.metadata?.size ?? "md",
                scrollable: dialog.metadata?.scrollable ?? false,
                placement: dialog.metadata?.placement ?? "center",
                className: dialog.metadata?.className ?? "",
                style: dialog.metadata?.style ?? {}
            }
        }
    }
)


const DialogSlice = createSecuredSlice({
    name: "dialogs",
    initialState: [] as DialogMetadataRef[],
    reducers: {
        closeDialog: (state, action: PayloadAction<string>) => {
            dialogStore.delete(action.payload)
            return state.filter(modal => modal.id !== action.payload)
        }
    },
    extraReducers: builder => {
        builder.addCase(openDialog.fulfilled, (state, action) => [...state, action.payload.metadata])
    }
})


export const {
    closeDialog
} = DialogSlice.actions


export default DialogSlice.reducer

export const getDialog = (id: string) => dialogStore.get(id)