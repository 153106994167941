import React, {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react"
import {useAppDispatch} from "@src/redux/hooks";
import BrokerService from "@common/services/broker.service";

interface IMqttContext {
    subscribe: (topic: string) => void
    unsubscribe: (topic: string) => void
    publish: (topic: string, message: string) => void
}

const MqttContext = createContext<IMqttContext | undefined>(undefined)

export const useBroker = (): IMqttContext => {
    const context = useContext(MqttContext)
    if (!context) {
        throw new Error('useBroker must be used within a BrokerProvider')
    }
    return context
}

export function BrokerProvider({children}: { children: ReactNode }) {
    const dispatch = useAppDispatch()
    const broker = useMemo(() => new BrokerService(dispatch), [dispatch])
    const [topics, setTopics] = useState<string[]>([])

    useEffect(() => {
        console.log("is broker connected",broker.isConnected())
        if (!broker.isConnected()) broker.connect()

        return () => broker.disconnect()
    }, [broker.isConnected()])

    const subscribe = (topic: string | string[]) => {
        const topicsToSubscribe = Array.isArray(topic) ? topic.filter(t => !topics.includes(t)) : [topic]

        broker.subscribe(topicsToSubscribe)
        setTopics([...topics, ...topicsToSubscribe])

    }

    const unsubscribe = (topic: string | string[]) => {
        const topicsToUnsubscribe = Array.isArray(topic) ? topic.filter(t => topics.includes(t)) : [topic]

        broker.unsubscribe(topicsToUnsubscribe)
        setTopics(topics.filter(t => !topicsToUnsubscribe.includes(t)))
    }

    const publish = (topic: string, message: string) => {
        broker.publish(topic, message)
    }


    return (
        <MqttContext.Provider value={{subscribe, unsubscribe, publish}}>
            {children}
        </MqttContext.Provider>
    )
}